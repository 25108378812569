@tailwind base;

.global-form-label {
  @apply absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 transition-all pointer-events-none;
}

.global-input {
  @apply w-full px-6 pt-7 pb-2 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light;
}

.table-optimized {
  @apply text-xxxs sm:text-xs lg:text-base md:text-sm px-1;
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @keyframes pulse-slight {
    0%,
    100% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.6;
    }
  }
  .animate-pulse-slight {
    animation: pulse-slight 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  @keyframes badgeHover {
    0% {
      transform: translateY(0) scale(1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    100% {
      transform: translateY(-2px) scale(1.05);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    }
  }
  .badge-hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    will-change: transform, box-shadow;
  }
  .badge-hover:hover {
    animation: badgeHover 0.4s ease-out forwards;
    cursor: pointer;
  }
  /* For mobile touch devices */
  @media (hover: hover) and (pointer: fine) {
    .badge-hover:hover {
      animation: badgeHover 0.4s ease-out forwards;
    }
  }
}

/* When the input is focused or has content, the label will move to the top and reduce its size */
input:focus + label,
input:not(:placeholder-shown) + label {
  @apply top-0 -translate-y-0 text-sm pt-1;
}

body {
  @apply font-sans;
}

.container:not(.map-parent) {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 0px;
  text-align: center;
  padding-bottom: 40px;
  margin-top: 10px;
}
.leaflet-map-container {
  z-index: 1; /* Adjust this value as needed */
}

.map-parent {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  padding-bottom: 0 !important;
  margin-top: -20px !important;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

body {
  margin: 0;
  background: #f1f2f5;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Pop Animation */
@keyframes pop {
  0% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.custom-zindex-less3 {
  z-index: 50;
}

.custom-zindex-less2 {
  z-index: 3000;
}

.custom-zindex-less {
  z-index: 4000;
}

.custom-zindex {
  z-index: 5000;
}

.my-icon {
  /* Pop on Appearance */
  animation: pop 0.5s ease-out;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.pulse {
  animation: pulse 2s infinite;
}

/* enforce images being square for messages component  */
.image-square {
  width: 3rem; /* or 48px, depending on your base font size */
  height: 3rem;
  object-fit: cover;
  border-radius: 0.375rem; /* matching the tailwind rounded-md */
  border: 1px solid black;
}

.map-container .leaflet-container {
  z-index: 4000;
}

body.barcode-scanner-active {
  background: transparent !important;
}

.barcode-scanner-active .hide-when-scanning {
  display: none;
}

.scanner-active-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}

.campaign-tooltip {
  @apply tooltip tooltip-left tooltip-primary;
}

@keyframes newStamp {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(-45deg);
  }
  40% {
    transform: scale(1.4) rotate(15deg);
  }
  60% {
    transform: scale(1.2) rotate(-10deg);
  }
  80% {
    transform: scale(1.3) rotate(5deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.animate-new-stamp {
  animation: newStamp 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  /* Using cubic-bezier for more bounce effect */
}

@keyframes sellStamp {
  0% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
  10% {
    transform: scale(1.1) rotate(5deg);
    opacity: 0.9;
  }
  30% {
    transform: scale(1.05) rotate(-3deg);
    opacity: 0.7;
  }
  60% {
    transform: scale(1) rotate(2deg);
    opacity: 0.4;
  }
  80% {
    transform: scale(0.95) rotate(-1deg);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.9) rotate(0);
    opacity: 0;
  }
}

.animate-sell-stamp {
  animation: sellStamp 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  /* Using a smoother cubic-bezier for the gradual fade */
}

@keyframes float {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-10px) rotate(2deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 10px rgba(255, 140, 0, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 20px rgba(255, 140, 0, 0.8)) drop-shadow(0 0 30px rgba(255, 165, 0, 0.3));
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(255, 140, 0, 0.5));
  }
}

@keyframes spinAndRipple {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-15deg);
  }
  15% {
    transform: rotate(360deg) scale(1.1);
  }
  20% {
    transform: rotate(350deg) scale(1);
  }
  25% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 140, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
  }
}

.teds-logo {
  transition: all 0.3s ease;
  animation: float 6s ease-in-out infinite, glow 3s ease-in-out infinite, spinAndRipple 10s ease-in-out infinite, ripple 10s ease-in-out infinite;
  border-radius: 50%;
}

.teds-logo:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 25px rgba(255, 140, 0, 0.8)) brightness(1.1);
  animation-play-state: paused;
}
